.SelectAvatarGIF {
  width: 530px;

  .SelectAvatarGIFSearch {
    width: 100%;
    margin-bottom: 24px;
  }

  .SelectAvatarGIFList {
    @apply relative;
    height: 400px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    img {
      cursor: pointer;
      border-radius: 16px;
    }
  }
}
