.correctGuess {
  @apply bg-success text-white rounded-sm cursor-not-allowed;
}

.incorrectGuess {
  @apply text-white bg-[#f44336] rounded-sm cursor-not-allowed;
}



.gameOver {
  margin-top: 2rem;
  text-align: center;
  @apply mb-10;
}

.keyboard {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 600px;
  margin: 1rem auto;
}

.keyboard button {
  width: 2rem;
  height: 2rem;
  margin: 0.2rem;
  font-size: 1rem;
  text-transform: uppercase;
}

.hangmanDrawing {
  margin: 0 auto;
  display: block;
}
