.container {
  text-align: center;
  padding: 2rem;
  position: relative;
}

.score {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.grid {
  display: inline-block;
  background-color: #bbada0;
  padding: 10px;
  border-radius: 10px;
  position: relative;
}

.row {
  display: flex;
}

.cell {
  width: 80px;
  height: 80px;
  background-color: #ccc0b3;
  margin: 5px;
  position: relative;
  border-radius: 5px;
}

.tile {
  width: 100%;
  height: 100%;
  line-height: 80px;
  text-align: center;
  font-size: 2rem;
  color: #776e65;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tile-2 {
  background-color: #eee4da;
}

.tile-4 {
  background-color: #ede0c8;
}

.tile-8 {
  background-color: #f2b179;
  color: #f9f6f2;
}

.tile-16 {
  background-color: #f59563;
  color: #f9f6f2;
}

.tile-32 {
  background-color: #f67c5f;
  color: #f9f6f2;
}

.tile-64 {
  background-color: #f65e3b;
  color: #f9f6f2;
}

.tile-128 {
  background-color: #edcf72;
  color: #f9f6f2;
}

.tile-256 {
  background-color: #edcc61;
  color: #f9f6f2;
}

.tile-512 {
  background-color: #edc850;
  color: #f9f6f2;
}

.tile-1024 {
  background-color: #edc53f;
  color: #f9f6f2;
}

.tile-2048 {
  background-color: #edc22e;
  color: #f9f6f2;
}

.gameOver {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  background-color: rgba(238, 228, 218, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.gameOver p {
  font-size: 3rem;
  color: #776e65;
}

.gameOver button {
  padding: 1rem 2rem;
  font-size: 1.5rem;
  background-color: #8f7a66;
  color: #f9f6f2;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}


