.letterBorder {
  @apply relative text-white ml-1 font-bold;
  /* Apply multiple drop shadows in one go */
  filter: drop-shadow(0 4.2px 4.2px rgba(157, 133, 255, 1))
    drop-shadow(0 10px 10px rgba(34, 153, 221, 0.333))
    drop-shadow(0 3px 3px rgba(0, 0, 0, 1));
}

.neon {
  box-shadow: 0 0 5px rgba(34, 153, 221, 0.333),
    0 0 10px rgba(34, 153, 221, 0.333), 0 0 20px rgba(34, 153, 221, 0.333),
    0 0 40px rgba(34, 153, 221, 0.333);
}
