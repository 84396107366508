/* Masonry Columns Container */
.masonryColumns {
  -webkit-column-count: 2; /* Chrome, Safari, Opera */
  -moz-column-count: 2; /* Firefox */
  column-count: 2; /* Standard CSS3 */
  column-gap: 16px;
  width: 100%;
  padding: 0; /* Ensure no padding is affecting the alignment */
}

.masonryColumns > div {
  break-inside: avoid-column;
  margin-bottom: 16px;
  margin-top: 0; /* Reset margin top for all items */
}

@media (min-width: 768px) {
  /* iPad screens */
  .masonryColumns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
}

@media (min-width: 1226px) {
  /* Large screens */
  .masonryColumns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }

  .masonryColumnsPage {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
}
