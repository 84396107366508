.container {
  // background-color: #0b5c2e;
  min-height: 100vh;
  color: white;
  @apply lg:p-[20px];
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.controls {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  button {
    padding: 10px 20px;
    font-size: 16px;
  }
}

.topRow {
  @apply flex justify-between max-w-[100%];

  margin-bottom: 20px;
}

.stockWaste {
  display: flex;
  margin-left: 20px;
  align-items: flex-start; // Align items to the top
}

.stock,
.waste {
  @apply w-[50px] h-[75px] md:w-[80px] md:h-[120px] min-h-[75px] lg:min-h-[120px];
  margin-right: 10px;
  position: relative;

  .emptySlot {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 1px dashed #ccc;
    border-radius: 5px;
  }
}

.foundations {
  display: flex;
  // @apply lg:mr-[20px];
  margin-right: 20px;
}

.foundation {
  @apply w-[50px] h-[75px] md:w-[80px] md:h-[120px]  min-h-[75px] lg:min-h-[120px];
  margin-left: 10px;
  position: relative;

  .emptySlot {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 1px dashed #ccc;
    border-radius: 5px;
  }
}

.tableau {
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
}

.pile {
  width: 80px;
  position: relative;
  min-height: 120px; // Ensure it's clickable even when empty
}

.cardBack {
  width: 100%;
  height: 100%;
  background-color: #7654ff; /* Blue background similar to standard card backs */
  border: 1px solid #000;
  border-radius: 5px;
  /* Optional: Add a pattern or image to represent the back of a card */
  background-image: linear-gradient(
    45deg,
    #7654ff 25%,
    #9d85ff 25%,
    #9d85ff 50%,
    #7654ff 50%,
    #7654ff 75%,
    #9d85ff 75%,
    #9d85ff 100%
  );
  background-size: 20px 20px;
}
