.card {
  @apply w-[50px] h-[75px] md:w-[80px] md:h-[120px];
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  position: absolute;
  left: 0;
  transition: transform 0.3s ease, top 0.3s ease, opacity 0.3s ease;
}

.card.disabled {
  pointer-events: none;
}

.faceDown {
  background-color: #7654ff;
  border: 1px solid #000;
}

.faceUp {
  background-color: white;
  border: 1px solid #000;
}

.cardContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
}

.rank {
  font-size: 24px;
  line-height: 1;
}

.suit {
  font-size: 24px;
  line-height: 1;
}

.selected {
  border: 2px solid yellow;
  transform: translateY(-10px); // Slight lift when selected
}
