.menuContainer {
  overflow-y: hidden;

  div {
    text-transform: uppercase;
    font-size: 13px;

    &:hover {
      cursor: pointer;
    }
  }
}

.mobileMenu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: top;
  @apply bg-gradient-to-b from-dark-default to-primary justify-start;

  button {
    align-self: flex-end;
    margin: 35px 45px 0 0;
    outline: none;
    border: none;
    background-color: transparent;
    text-transform: uppercase;
    font-family: inherit;
    font-size: 13px;
    color: white;

    &:hover {
      cursor: pointer;
    }
  }

  ul {
    list-style: none;
    li {
      // margin: 24px 0;
      overflow-y: hidden;
      user-select: none;

      div {
        text-align: center;
        text-transform: capitalize;
        font-size: 20px;
      }

      &:hover {
        font-style: italic;
        cursor: pointer;
      }
    }
  }

  .contact {
    @apply flex w-full mx-auto justify-center items-center gap-x-[28px];

    div {
      @apply text-center uppercase;
    }
  }
}
