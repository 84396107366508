@keyframes flash {
  0%,
  100% {
    background-color: #c2c1c1; /* Light grey background */
  }
  50% {
    @apply bg-primary;
  }
}

.skeletonLoader {
  animation: flash 3.5s infinite ease-in-out; /* Flashing animation */
}

.shimmerWrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.shimmer {
  position: absolute;
  top: 0;
  left: -50%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(118, 84, 255, 0.2),
    rgba(118, 84, 255, 0.4),
    rgba(118, 84, 255, 0.6),
    transparent
  );
  opacity: 0; /* Start fully transparent */
}

.shimmer1 {
  animation: shimmerAnimation 2s infinite linear;
}

.shimmer2 {
  animation: shimmerAnimation 2s infinite linear;
  animation-delay: 1s; /* Starts halfway through the first gradient's animation */
}

@keyframes shimmerAnimation {
  0%,
  100% {
    left: -50%;
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  50% {
    left: 100%;
    opacity: 1;
  }
  70% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
}
