.imageContainer {
  position: relative;
  overflow: hidden;
  @apply cursor-pointer relative min-h-[100px] w-full max-h-[600px] 
  h-auto overflow-hidden rounded-t-lg;
}

.imageContainer .overlayText {
  @apply rounded-t-2xl  text-white/90;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(
    rgba(29, 26, 75, 0),
    rgba(29, 26, 75, 0.2) 20%,
    rgba(29, 26, 75, 0.3) 30%,
    rgba(29, 26, 75, 0.4) 40%,
    rgba(29, 26, 75, 0.5) 50%,
    rgba(29, 26, 75, 0.6) 60%
  );
  display: flex;
  justify-content: center;
  height: 0%;
  overflow: hidden;
  transition: 0.7s;
}

.imageContainer:hover .overlayText {
  @apply overflow-hidden text-[15px] font-light backdrop-blur-sm md:h-[100%];
}

.imageContainer:not(:hover) .overlayText {
  @apply text-transparent;
}

.bottomContainer {
  @apply flex h-auto max-h-[280px] cursor-default flex-col space-y-6 rounded-b-xl 
  bg-[rgba(255,255,255,0.07)] py-4 px-3  sm:px-4 sm:py-5;
}

.blockchainLogo {
  @apply w-[15px] h-[15px] min-w-[15px] sm:w-[18px] sm:h-[18px] sm:min-w-[18px] 
  object-contain ml-0 hidden sm:flex;
}

.opacityHover:hover {
  @apply opacity-60;
  transition: 0.3s;
}

.iconCheck {
  opacity: 0;
  height: 0;
}
.iconCheck:hover {
  @apply opacity-100;
  height: 50px;
  width: 50px;
  // transition: 0.1s;
  position: absolute;
  top: 16px;
  right: 16px;
}
