/* styles/MemoryGame.module.css */

.container {
  text-align: center;
}

.cardGrid {
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: 1rem;
  justify-content: center;
  margin-top: 2rem;
}

.card {
  position: relative;
  width: 80px;
  height: 80px;
}

.card img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  //   @apply object-contain;
}

.card .front {
  position: absolute;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  @apply object-contain;
}

.card .back {
  position: absolute;
  backface-visibility: hidden;
  cursor: pointer;
  @apply transition hover:scale-110;
}

.card .flipped .front {
  transform: rotateY(0);
}

.card .flipped .back {
  transform: rotateY(180deg);
}
